<template>
  <div>
    <div v-show="page == 1" class="add-info">
      <p class="title">テンプレート ＞ 作成</p>
      <div class=" add-form">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="9rem" class="demo-ruleForm">
          <el-form-item label="カテゴリー" prop="category">
            <el-radio-group v-model="ruleForm.category">
              <el-radio :label="1">説明会</el-radio>
              <el-radio :label="2">エントリー</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="テンプレ名" prop="name">
            <el-input placeholder="" maxlength="20" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="件 名" prop="title">
            <el-input :maxlength="100" placeholder="【24卒新卒 営業職募集】ぜひあなたに活躍いただきたいです!!" v-model="ruleForm.title"></el-input>
          </el-form-item>
          <p class="export-tem" @click="templateInsert()">テンプレを入れる</p>
<!--          <el-form-item label="本 文" prop="bodyContent">-->
<!--            <textarea class="el-textarea__inner" :autosize="{ minRows: 8}"  :placeholder="defaultData" type="textarea" v-model="ruleForm.bodyContent" show-word-limit :maxlength="2000"></textarea>-->
<!--          </el-form-item>-->
          <el-form-item label="本 文" prop="bodyContent">
            <el-input @input="changeTextArea" @change="changeTextArea" @blur="changeTextArea" :autosize="{ minRows: 8}"  placeholder="はじめまして！
企業名 の代表 ●●です。

現在ご登録されているあなたのキャリアシートを拝見し
ぜひともあなたの＜惹かれた点/良いと思った点＞を弊社で発揮してほしいと感じましたので、
こちらのスカウトメールをお送りしております。

現在募集しているお仕事は下記の通りです。
＜お仕事内容を記載＞

興味を持っていただけましたらぜひお気軽に
＜説明会/募集要項＞にエントリーくださいませ!!" type="textarea" v-model="ruleForm.bodyContent" show-word-limit :maxlength="2000"></el-input>
          </el-form-item>

          <div @click="changePage(2)" class="form-button">
            <div>確認画面へ</div>
          </div>
          <!--            <el-form-item>-->
          <!--              <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>-->
          <!--              <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          <!--            </el-form-item>-->
        </el-form>
      </div>
    </div>
    <div v-show="page == 2" class="add-info">
      <p class="title">テンプレート ＞ 確認画面</p>

      <div class="content">
        <div class="item">
          <span class="title">カテゴリー</span>
          <span class="item-content">{{ruleForm.category == 1 ? '説明会' : ruleForm.category == 2 ? 'エントリー' : ''}}</span>
        </div>
        <div class="item">
          <span class="title">テンプレ名</span>
          <span class="item-content">{{ruleForm.name}}</span>
        </div>
        <div class="item">
          <span class="title">件 名</span>
          <span class="item-content">{{ruleForm.title}}</span>
        </div>
        <div class="item">
          <span class="title">本 文</span>
          <span class="item-content bottom-content">{{ruleForm.bodyContent}}</span>
        </div>
        <div class="item-buttons">
          <div @click="changePage(1)" class="next-button green-btn">編集画面へ戻る</div>
          <div @click="changePage(3)" class="next-button yellow-btn">保存</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "scoutDetail",

  data() {
    var chkTitle= (rule, value, callback) => {
      if (!(this.ruleForm.title)) {
        console.log(value)
        callback(new Error('「件 名」が入力されていません'));
      } else {
        var newContent= this.ruleForm.title.replace(/\r\n/g, "")  
        newContent= newContent.replace(/\n/g, "");
        const mailMatch = /([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+/g
        const urlMatch = /(?:(http|https|ftp|smtp):\/\/)?(?:[a-z0-9-]+\.)+[a-z]{2,20}(?:(?:\/(?:[\w#!:.?+=&%@!\/-])*)+\/?)?/g
        if (newContent.match(mailMatch) && newContent.match(mailMatch).length > 0) {
          callback(new Error('「件 名」にメールアドレスを入力しないてください'))
        } else if (newContent.match(urlMatch) && newContent.match(urlMatch).length > 0) {
          callback(new Error('「件 名」にURLを入力しないてください'));
        } else if (newContent.length >= 10) {
          const numMatch = /^[0-9]+$/
          const tenMatch = /^[0-9]{10}$/
          var subNewContent = newContent.replace(/-/g, "").replace(/\([^\)]*\)/g, "") 
          if (subNewContent.length >= 10) {
            for (var i = 0; i + 10 <= subNewContent.length; i++) {
              var strStart = subNewContent.substr(i, 1)
              if (numMatch.test(strStart)) {
                var strObject = subNewContent.substr(i, 10)
                if (tenMatch.test(strObject)) {
                  callback(new Error('「件 名」に電話番号を入力しないてください'))
                  break
                }
              }
            }
          }
        }
        callback();
      }
    };
    var bodyContent = (rule, value, callback) => {
      if (!(this.ruleForm.bodyContent)) {
        console.log(value)
        callback(new Error('「本 文」が入力されていません'));
      } else {
        var newContent= this.ruleForm.bodyContent.replace(/\r\n/g, "")  
        newContent= newContent.replace(/\n/g, "");
        const mailMatch = /([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+/g
        const urlMatch = /(?:(http|https|ftp|smtp):\/\/)?(?:[a-z0-9-]+\.)+[a-z]{2,20}(?:(?:\/(?:[\w#!:.?+=&%@!\/-])*)+\/?)?/g
        if (newContent.match(mailMatch) && newContent.match(mailMatch).length > 0) {
          callback(new Error('「本 文」にメールアドレスを入力しないてください'))
        } else if (newContent.match(urlMatch) && newContent.match(urlMatch).length > 0) {
          callback(new Error('「本 文」にURLを入力しないてください'));
        } else if (newContent.length >= 10) {
          const numMatch = /^[0-9]+$/
          const tenMatch = /^[0-9]{10}$/
          var subNewContent = newContent.replace(/-/g, "").replace(/\([^\)]*\)/g, "") 
          if (subNewContent.length >= 10) {
            for (var i = 0; i + 10 <= subNewContent.length; i++) {
              var strStart = subNewContent.substr(i, 1)
              if (numMatch.test(strStart)) {
                var strObject = subNewContent.substr(i, 10)
                if (tenMatch.test(strObject)) {
                  callback(new Error('「本 文」に電話番号を入力しないてください'))
                  break
                }
              }
            }
          }
        }
        callback();
      }
    };
    return {
      page: 1,
      ruleForm: {
        bodyContent: ''
      },
      rules: {
        category: [
          { required: true, message: '「カテゴリー」が設定されていません', trigger: 'change' }
        ],
        name: [
          { required: true, message: '「テンプレ名」が入力されていません', trigger: 'blur' }
        ],
        title: [
          // { required: true, message: '「件 名」が入力されていません', trigger: 'blur' }
          { validator: chkTitle, trigger: 'blur' }
        ],
        bodyContent: [
          { validator: bodyContent, trigger: 'blur' }
        ]
      },

      aaa: '',

      defaultData: 'はじめまして！\n' +
          '企業名 の代表 ●●です。\n' +
          '\n' +
          '現在ご登録されているあなたのキャリアシートを拝見し\n' +
          'ぜひともあなたの＜惹かれた点/良いと思った点＞を弊社で発揮してほしいと感じましたので、\n' +
          'こちらのスカウトメールをお送りしております。\n' +
          '\n' +
          '現在募集しているお仕事は下記の通りです。\n' +
          '＜お仕事内容を記載＞\n' +
          '\n' +
          '興味を持っていただけましたらぜひお気軽に\n' +
          '＜説明会/募集要項＞にエントリーくださいませ!!'
    }
  },

  props:{
    status: {
      type: Number
    },

    id: {
      type: Number
    }
  },

  watch: {
    status: {
      handler: function(newValue,oldValue) {
        if(this.status == 2) {
          this.ruleForm = {};
        } else if(this.status == 3) {
          // console.log(this.id)
          this.gerDetail();
        } else if (this.status == 4) {
          this.gerDetail();
          this.page = 2;
        }
      },
      immediate: true
    }

  },

  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
  },

  methods: {
    changeTextArea(e) {
      // console.log("ssssss")

      this.$forceUpdate()
      // if(this.ruleForm.bodyContent) {
      //   this.$nextTick(() =>{
      //     this.$refs['ruleForm'].clearValidate(['bodyContent']);
      //   })
      // }
    },

    async changePage(type) {
      if(type == 1) {
        //下一页
        this.page = type;
      } else if (type ==2) {
        let that = this;
        let res = await this.$axios({url: `/compScout/chkContent`, data: {title: this.ruleForm.title, bodyContent: this.ruleForm.bodyContent}, method: "post"});
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            if(res.code == '000') {
              if(that.ruleForm.bodyContent) {
                that.$refs['ruleForm'].clearValidate(['bodyContent']);
              }

              that.$forceUpdate()
              that.page = type;
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else if (type == 3) {
        //返回
        // this.$emit('changeType', 1);
        await this.saveDetail();
        this.page = 1;
        this.$emit('changeType', 1);
      }
    },

    async gerDetail() {
      let res = await this.$axios({url: `/compScout/tempDetail`, data: {id: this.id}, method: "post"});
      if(res.code == '000') {
        this.ruleForm = res.data;
        this.$forceUpdate()
      }
    },

    async saveDetail() {
      this.ruleForm.operatorId = this.userInfo.id;
      this.ruleForm.compId = this.userInfo.compId;
      if(this.status == 2) {
        //新增
        let res = await this.$axios({url: `/compScout/temp/add`, data: this.ruleForm, method: "post"});
        if(res.code == '000') {
          this.$message({
            message: 'テンプレ名「' + this.ruleForm.name + '」を保存しました',
            type: 'success'
          });
        }
      } else if(this.status == 3) {
        //编辑
        let res = await this.$axios({url: `/compScout/temp/update`, data: this.ruleForm, method: "post"});
        if(res.code == '000') {
          this.$message({
            message: 'テンプレ名「' + this.ruleForm.name + '」を保存しました',
            type: 'success'
          });
        }
      }
      this.$emit('getList');
      this.ruleForm = {};
    },

    templateInsert() {
      // console.log(this.ruleForm.category)
      this.ruleForm.bodyContent = 'はじめまして！\n' +
          '企業名 の代表 ●●です。\n' +
          '\n' +
          '現在ご登録されているあなたのキャリアシートを拝見し\n' +
          'ぜひともあなたの＜惹かれた点/良いと思った点＞を弊社で発揮してほしいと感じましたので、\n' +
          'こちらのスカウトメールをお送りしております。\n' +
          '\n' +
          '現在募集しているお仕事は下記の通りです。\n' +
          '＜お仕事内容を記載＞\n' +
          '\n' +
          '興味を持っていただけましたらぜひお気軽に\n' +
          '＜説明会/募集要項＞にエントリーくださいませ!!'
      ;
      this.changeTextArea();
      this.$forceUpdate()
    },

    submitForm() {

    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
    },
  }


}
</script>

<style scoped>
.add-info {
  margin-top: 3.125rem;
}

.add-info>.title {

  font-style: normal;
  font-weight: 600;
  font-size: .9375rem;
  line-height: 1.1rem;

  color: #424242;

  margin: 3.125rem 0 1.4rem 0;
}

.content {
  flex: 1;
  /*height: 100vh;*/
  background: #FFFFFF;
  box-shadow: .234375rem .234375rem 0px rgba(0, 0, 0, 0.05);
  border-radius: .625rem;

  box-sizing: border-box;

  padding: .391rem 1.172rem 1.953rem;
}

.content .item {
  display: flex;
  margin-top: 2.734rem;
  margin-right: 6.094rem;
}

.content .item:first-child .item-content {
  border-bottom: 0;
  padding-bottom: 0;
}

.content .item .bottom-content {
  border-bottom: 0 !important;
}

.content .item .title {
  width: 10.122rem;

  font-style: normal;
  font-weight: 600;
  font-size: 1.09375rem;
  line-height: 1.9rem;
  text-align: center;
  margin-right: 2.456rem;
  color: #CCCCCC;
  /*padding-bottom: 2.709rem;*/
}

.content .item .item-content {
  flex: 1;

  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.9rem;
  /* identical to box height, or 150% */

  padding-bottom: 2.709rem;
  border-bottom: 0.0585938rem solid #CCCCCC;
  color: #000000;

  white-space: pre-wrap;
}

.content .next-button {
  width: 15.375rem;
  height: 2.5rem;
  margin: 5.444rem auto 0;
  background: #FFFFFF;
  border: 0.078125rem solid #424242;
  border-radius: 1.95312rem;

  font-style: normal;
  font-weight: 700;
  font-size: 1.09375rem;
  line-height: 2.5rem;
  text-align: center;

  color: #424242;
  cursor: pointer;
}

.item-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-buttons div {
  margin: 5.444rem 0 0!important;
  /*margin-top: 5.444rem;*/
}

.item-buttons div:first-child {
  /*margin: 5.444rem 5.16rem;*/
  margin-right: 5.16rem !important;
}



.add-info .add-form {
  width: 100%;
  /*height: 20vh;*/
  background: #FFFFFF;
  box-shadow: 2.34375px 2.34375px 0px rgba(0, 0, 0, 0.05);
  border-radius: .625rem;
  box-sizing: border-box;
  padding: 1.857rem;
}

.add-info .add-form /deep/ .el-form-item__label {
  padding-right: 1.153rem;


  font-style: normal;
  font-weight: 600;
  font-size: 1.09375rem;
  text-align: center;
  line-height: 3.906rem;
}

.add-info .add-form /deep/ .el-input__inner,
.add-info .add-form /deep/ .el-textarea__inner{
  /*width: 39.062rem;*/
  height: 3.906rem;

  background: #FFFFFF;
  border: 0.585938px solid #CCCCCC;
  box-shadow: inset 0.78125px 0.78125px 3.90625px rgba(0, 0, 0, 0.23);
  border-radius: .390625rem;

  font-size: 1.09375rem;
  color: black;
}

.add-info .add-form /deep/ .el-input__inner::placeholder,
.add-info .add-form /deep/ .el-textarea__inner::placeholder{

  font-style: normal;
  font-weight: 400;
  font-size: 1.09375rem;
  line-height: 1.3rem;

  color: #A3A3A3;
}

.add-info .add-form /deep/ .hold-time .el-input__inner {
  width: 17.186rem;
}

.add-info .add-form /deep/ .el-input,
.add-info .add-form /deep/ .el-textarea{
  width: 100%;
}

/*.add-info .add-form /deep/ .hold-time .el-clo*/

/*.el-form-item__label*/


.add-info .add-form /deep/ .el-textarea__inner {
  height: auto;
}

.add-info .add-form /deep/ .el-icon-time:before {
  display: inline-block;
  /*margin-top: .26rem;*/


  line-height: 3.906rem;
  /* width: 7rem; */
  /* height: 1.172rem; */
  font-size: 1.094rem;
}

.add-info .add-form /deep/ .el-textarea__inner {
  padding: 1.25rem;
}

.add-info .add-form /deep/ .el-form-item__content {
  line-height: 3.906rem;
}

.add-info .add-form /deep/ .el-radio__label {

  font-style: normal;
  font-weight: 400;
  font-size: 1.09375rem;
  /*line-height: 13px;*/
  line-height: normal;
  color: #000000;
}

.add-info .add-form /deep/ .el-radio__inner {
  background: #D9D9D9;
  border: none;
  width: 1.328rem;
  height: 1.328rem;
}

.add-info .add-form /deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #15B1CE;
}

.add-info .add-form /deep/ .el-radio__inner::after {
  width: .62rem;
  height: .62rem;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-button>div {
  width: 15.375rem;
  height: 2.5rem;

  line-height: 2.5rem;
  background: #FFFFFF;
  border: 0.78125px solid #CCCCCC;
  border-radius: 1.95312rem;


  font-style: normal;
  font-weight: 700;
  font-size: 1.09375rem;
  text-align: center;
  transition: .3s;
  color: #CCCCCC;
  cursor: pointer;
  /*margin-right: 1.157rem;*/
}

.form-button>div:hover {
  background: #15B1CE;
  color: white;
}

.name {

  font-style: normal;
  font-weight: 400;
  font-size: 1.09375rem;
  /*line-height: 13px;*/
  line-height: 3.906rem;
  color: #000000;
}

.export-tem {

  font-style: normal;
  font-weight: 400;
  font-size: .78125rem;
  line-height: .9rem;
  text-align: right;
  cursor: pointer;
  color: #2876F6;
  margin-bottom: .741rem;
}
</style>
