<template>
  <div class="container">
    <admin-title></admin-title>
    <div class="top">
      <div class="t-content">
        <div class="left">
          <div class="num">
            <span class="title">WinC スカウトチケット数</span>
            <span class="now-num">{{topInfo.residueNum}}&nbsp;&nbsp;</span>
            <span class="total-num">/ {{ topInfo.totalNum }}</span>
          </div>
          <div class="date">
            <span class="title">購入日時</span>
            <span class="grey">{{topInfo.createTimeLabel}}</span>
          </div>
          <div class="date">
            <span class="title">送信期限</span>
            <span>{{topInfo.expirationDateLabel}}</span>
          </div>
        </div>
        <el-tooltip class="item" effect="dark" content="WinC Careerは学生一人ひとりに企業が向き合い、想いを伝えられるよう、一括送信はできない仕様です。" placement="bottom-end">
          <div class="right">
            <img src="../../assets/images/ae/ques_blue.png">
            <span>スカウトの想い</span>
          </div>
        </el-tooltip>

      </div>
    </div>

    <div class="bottom">
      <div class="instrument">スカウト</div>

      <div v-if="type == 1" class="scout-table">
        <div class="table-title">テンプレート</div>
        <div class="table-button" @click="changeType(2)">＋　新規作成</div>
        <div  class="table-box">
          <div class="table-content">
            <div class="table-c">
              <el-table
                  :data="tableData"
                  height="19.2rem"
                  style="width: 100%">
                <el-table-column
                    prop="name"
                    label="テンプレ名"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    prop="title"
                    show-overflow-tooltip
                    label="件名">
                </el-table-column>
                <el-table-column
                    prop="bodyContent"
                    label="本文">
                  <template slot-scope="scope">
                    <p class="ellipsis">{{scope.row.bodyContent}}</p>
                  </template>
                </el-table-column>
                <el-table-column
                    min-width="40%"
                    prop="item4"
                    label="カテゴリー">
                  <template slot-scope="scope">
                    <span v-if="scope.row.category == 1">説明会</span>
                    <span v-if="scope.row.category == 2">エントリー</span>
                  </template>
                </el-table-column>
                <el-table-column label="" min-width="100%">
                  <template slot-scope="scope">
                    <template v-if="scope.$index == 0">


                    </template>
                    <div class="buttons">
                      <div class="button edit" @click="handleEdit(scope.$index, scope.row)">編集</div>
                      <div class="button delete" @click="handleDelete(scope.$index, scope.row)">削除</div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <scout-detail @getList="getList" :status="type" :id="id" @changeType="changeType" v-if="type == 2 || type == 3 || type == 4"></scout-detail>
    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import scoutDetail from '@/components/pageComponents/scoutDetail.vue'
export default {
  name: "scout",

  components: {
    scoutDetail,
  },

  data() {
    return {
      userInfo: {},
      topInfo: {},

      //2: 新增   3：编辑
      type: 1,
      tableData: [],

      id: 0
    }
  },


  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
    let type = this.$route.query.type;
    if((type == 3 || type == 4) && this.$route.query.id) {
      this.type = parseInt(type);
      this.id = parseInt(this.$route.query.id);
    }
    this.getTopInfo();
    this.getList();
  },

  methods: {
    async getTopInfo() {
      let res = await this.$axios({url: `/compScout/top`, data: {token: this.userInfo.token}, method: "post"});
      if(res.code == '000' && res.data) {
        this.topInfo = res.data;
      }
    },

    async getList() {
      let res = await this.$axios({url: `/compScout/tempList`, data: {compId: this.userInfo.compId}, method: "post"});
      if(res.code == '000') {
        this.tableData = res.data;
      }


    },


    handleEdit(index, row) {
      this.id = row.id;
      this.type = 3;
    },
    handleDelete(index, row) {
      let that=this;

      this.$confirm('テンプレートを削除してよろしいですか？', '', {
        confirmButtonText: 'はい',
        cancelButtonText: 'キャンセル',
        center: true
      }).then(() => {
        that.deleteItem(row.id);
      }).catch(() => {

      });
      // console.log(index, row);
    },

    async deleteItem(id) {

      let res = await this.$axios({url: `/compScout/temp/delete`, data: {id: id}, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });
      }
      this.getList();
    },

    changeType(type) {
      this.type = type;
    }
  }
}
</script>

<style scoped src="../../style/scout.css">

</style>
